import jump from 'jump.js'

const handleClick = (hash: string): void => {
  const selector: string = `#${hash.replace('#anchor-', '')}`
  jump(selector, {
    duration: 500,
    callback(): void {
      const target: HTMLAnchorElement | null = document.querySelector(selector)
      if (target) {
        target.tabIndex = 0
        target.focus()
        target.blur()
        target.tabIndex = -1
      }
    },
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const $anchorNodes: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
    'a[href^="#anchor-"]'
  )
  const $anchors: Array<HTMLAnchorElement> = Array.prototype.slice.call(
    $anchorNodes,
    0
  )
  const hash: string = location.hash

  if (hash.indexOf('#anchor-') !== -1) {
    handleClick(hash.replace('#anchor-', ''))
  }

  $anchors.forEach(anchor => {
    anchor.addEventListener('click', e => {
      e.preventDefault()
      const { scrollto } = anchor.dataset
      if (scrollto) {
        document.body.dataset.currentSection = scrollto
      }
      handleClick(anchor.hash.replace('#anchor-', ''))
    })
  })

  const scrollBtn: HTMLAnchorElement | null = document.querySelector(
    '#js-top__pagination__scroll-btn'
  )
  if (scrollBtn) {
    scrollBtn.addEventListener('click', e => {
      e.preventDefault()
      const { currentSection } = document.body.dataset
      if (currentSection === 'kv') {
        handleClick('Vision')
      } else if (currentSection === 'vision') {
        handleClick('News')
      }
    })
  }
})
