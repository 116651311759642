interface State {
  opened: boolean
}

type Lang = 'ja' | 'en'

class Drawer {
  state: State = {
    opened: false,
  }
  $body: HTMLElement | null = null
  $menuBtn: HTMLAnchorElement | null = null
  $closeBtn: HTMLAnchorElement | null = null
  $overlay: HTMLAnchorElement | null = null
  additionalName: string = 'is-opened--drawer-menu'
  lang: Lang = 'ja'
  labels: {
    ja: { openedTxt: string; closedTxt: string }
    en: { openedTxt: string; closedTxt: string }
  } = {
    ja: {
      openedTxt: 'メニューを閉じる',
      closedTxt: 'メニューを開く',
    },
    en: {
      openedTxt: 'Close menu',
      closedTxt: 'Open menu',
    },
  }

  init = (): void => {
    this.$menuBtn = document.querySelector<HTMLAnchorElement>(
      '#js-hamburger-btn'
    )
    this.$overlay = document.querySelector<HTMLAnchorElement>('#js-overlay')
    this.$body = document.body as HTMLElement
    if (this.$menuBtn) {
      const { lang } = this.$menuBtn.dataset
      this.lang = lang === 'ja' || lang === 'en' ? lang : 'ja'
      this.$menuBtn.addEventListener('click', this.toggle)
    }
    if (this.$overlay) {
      this.$overlay.addEventListener('click', this.close)
    }
  }

  _update = (): void => {
    const { opened } = this.state
    if (this.$body && this.$menuBtn) {
      if (opened) {
        this.$body.classList.add(this.additionalName)
        this.$menuBtn.setAttribute(
          'aria-label',
          this.labels[this.lang].openedTxt
        )
      } else {
        this.$body.classList.remove(this.additionalName)
        this.$menuBtn.setAttribute(
          'aria-label',
          this.labels[this.lang].closedTxt
        )
      }
    }
  }

  open = (): void => {
    this.state.opened = true
    this._update()
  }

  close = (): void => {
    this.state.opened = false
    this._update()
  }

  toggle = (): void => {
    const { opened } = this.state
    this.state.opened = !opened
    this._update()
  }
}

const drawer = new Drawer()

export default drawer
