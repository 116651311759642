import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'

type Lang = 'ja' | 'en'

window.addEventListener('load', function() {
  const isTop = document.body.classList.contains('-nav-top')
  if (!isTop) return

  const swiper: Swiper | any = new Swiper('.c-mainvis', {
    direction: 'horizontal',
    effect: 'slide',
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    init: false,
  })

  swiper.on('init', () => {
    swiper.$el
      .find('.swiper-pagination-bullet-active')
      .attr('aria-current', 'location')
  })

  swiper.on('slideChange', () => {
    swiper.$el.find('.swiper-pagination-bullet').removeAttr('aria-current')
    swiper.$el
      .find('.swiper-pagination-bullet-active')
      .attr('aria-current', 'location')
  })
  swiper.init()

  const playBtn: HTMLAnchorElement | null = document.querySelector(
    '#js-mainvis__play-btn'
  )

  const labels: {
    ja: { playingTxt: string; pausedTxt: string }
    en: { playingTxt: string; pausedTxt: string }
  } = {
    ja: {
      playingTxt: '一時停止',
      pausedTxt: '再生',
    },
    en: {
      playingTxt: 'Pause',
      pausedTxt: 'Play',
    },
  }

  if (playBtn) {
    playBtn.addEventListener('click', () => {
      const { lang: _lang } = playBtn.dataset
      const lang: Lang = _lang === 'en' || _lang === 'ja' ? _lang : 'ja'
      if (swiper.autoplay) {
        if (swiper.autoplay.running) {
          swiper.autoplay.stop()
          playBtn.classList.add('-pause')
          playBtn.setAttribute('aria-label', labels[lang].pausedTxt)
        } else {
          swiper.autoplay.start()
          playBtn.classList.remove('-pause')
          playBtn.setAttribute('aria-label', labels[lang].playingTxt)
        }
      }
    })
  }
})
