import $ from 'jquery'
import './lib/jquery.easing.1.3.min'

$(function() {
  $('.content_acordion_link').on('click', function() {
    $(this)
      .next()
      .slideToggle(300)
    $(this).toggleClass('active') //追加部分
  })
})
